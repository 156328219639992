import React from "react"

import "./footer.scss"

import ButtonUp from "./button-up"
import SocialButtons from "./social-buttons"

export default () => (
  <footer>
    <ButtonUp />
    <h2 className="footerTitle">Sazināmies!</h2>
    <p className="white tc mb0">chalete@inbox.lv <span className="ph3">|</span> 26 222 279</p>
    <br />
    <p className="white tc pb0">Noliktavas Adrese - "Avoti" - 9, Aizstrautnieki, Dobeles pagasts, Dobeles novads, LV-3701</p>
    {/*<SocialButtons />*/}
    <p className="copyright">Visas tiesības aizsargātas {new Date().getFullYear()} Chalete</p>
    {/* <p className="credit">Mājaslapu apkalpo <a href="https://resun.lv" target="_blank" rel="noopener noreferrer" className="link white dim underline">Resun Solutions</a></p> */}
  </footer>
)
