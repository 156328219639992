import React from "react"

import "./button-down.scss"

export default () => {

  function scrollUp(){
    window.scrollTo(0,0);
  }

  return(
    <div className="button-up" onClick={scrollUp}>
      <p className="button-arrow">&#8593;</p>
    </div>
  )

}
