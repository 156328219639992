import React from "react"
import { Link } from "gatsby"
import PropTypes, { func } from "prop-types"

import "./header.scss"

import Icon from "../components/icon"
import HeaderButton from "../components/header-button"

function toggleNav () {
  var el = document.querySelector(".header-buttons-container");
  el.classList.toggle("header-buttons-container--shown");
}

const Header = ({ siteTitle }) => (
  <header>
      <div className="header-wrap">
        <h5 className="header-title">
          <Link to="/" className="header-link">
            <Icon />
            {siteTitle}
          </Link>
        </h5>
        <button className="header-toggle" onClick={()=>toggleNav()}>Izvēlne</button>
      </div>
      
      <div className="header-buttons-container">
        
        <Link to="/" style={{
          fontFamily:`sans-serif`,
          lineHeight:`30px`,
          float:`left`,
          margin:`0 1rem`,
          color:`black`
        }} className="link dim no-underline">
          Sākums
        </Link>

        <Link to="/par-uznemumu" style={{
          fontFamily:`sans-serif`,
          lineHeight:`30px`,
          float:`left`,
          margin:`0 1rem`,
          color:`black`
        }} className="link dim no-underline">
          Par uzņēmumu
        </Link>
        
        <Link to="/produkti" style={{
          fontFamily:`sans-serif`,
          lineHeight:`30px`,
          float:`left`,
          margin:`0 1rem`,
          color:`black`
        }} className="link dim no-underline">
          Produkti
        </Link>

        <Link to="/#kontakti" style={{
          fontFamily:`sans-serif`,
          lineHeight:`30px`,
          float:`left`,
          margin:`0 1rem`,
          color:`black`
        }} className="link dim no-underline">
          Kontakti
        </Link>
      </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
